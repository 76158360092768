import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import Img from "components/image";
import DefaultLayout from "components/layout/default";
import { IMAGE_BACKGROUND_PROPS } from "constants/index";
import { Oval } from "components";
import tsLogo from "images/logos/ts-logo.png";

const LEVER_POSTINGS_ENDPOINT =
  "https://api.lever.co/v0/postings/horangi?mode=json";

function CareersPage(props) {
  let data = useStaticQuery(QUERY);
  let pageContent = data.pageContent.childPagesJson;
  let initialJobPostings = data.lever.edges.map(edge => edge.node);
  let [jobPostings, setJobPostings] = React.useState(initialJobPostings);

  React.useEffect(() => {
    fetchLeverPostings();
  }, []);

  async function fetchLeverPostings() {
    let response = await fetch(LEVER_POSTINGS_ENDPOINT);
    let data = await response.json();
    if (isEmpty(data) === false) {
      setJobPostings(data);
    }
  }

  function renderJobPostings() {
    return jobPostings.map(posting => {
      let id = posting.lever_id || posting.id;
      return (
        <div
          key={id}
          className="flex justify-between items-start md:items-center flex-col md:flex-row mb-8 md:mb-0"
        >
          <div className="font-bold my-0 md:my-2 md:my-4 flex-1">
            {posting.text}
          </div>
          <div className="mt-1 md:my-4 w-32">
            {posting.categories.commitment}
          </div>
          <div className="mt-1 md:my-4 w-32">{posting.categories.location}</div>
          <div className="mt-4 md:my-4 flex-none">
            <a
              className="btn btn-sm btn-orange-outline inline-block"
              href={posting.hostedUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View More
            </a>
          </div>
        </div>
      );
    });
  }

  return (
    <DefaultLayout
      location={props.location}
      title={pageContent.title}
      description={pageContent.description}
    >
      <section className="relative min-h-screen flex items-center">
        <div className="container mx-auto px-4 sm:px-8 z-10 relative">
          <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight whitespace-pre-wrap">
            {pageContent.headerText}
          </h3>
          <p className="text-center mt-4">{pageContent.subHeaderText}</p>
        </div>
        <Img
          {...IMAGE_BACKGROUND_PROPS}
          fluid={data.backgroundImage.childImageSharp.fluid}
          alt="Background"
        />
      </section>
      <section id="TAFEP-logo" className="relative z-10 -mt-40 sm:-mt-48">
        <Oval
          height="280"
          color="white"
          className="flex justify-center items-center -mt-5 sm:mt-3"
        >
          <div className="flex flex-col sm:flex-row justify-center text-black">
            <div className="sm:max-w-2xl xxl:max-w-4xl w-full sm:w-2/3 lg:w-full my-auto p-10 sm:p-0">
              <p className="font-semibold text-center sm:text-left text-sm sm:text-lg xxl:text-xl">
                Horangi is recognized by Tripartite Appliance (TAFEP) for our
                good employment practices according to the Tripartite Standards.{" "}
                <a
                  className="underline text-orange text-center sm:text-left block sm:block xxl:inline"
                  href="https://www.tal.sg/tafep/Getting-Started/Progressive/Tripartite-Standards"
                >
                  Click here for more details.
                </a>
              </p>
            </div>
            <div>
              <img
                src={tsLogo}
                alt=""
                className="h-24 sm:h-40 xxl:h-48 mx-auto sm:mx-0 -mt-5 sm:mt-0"
              />
            </div>
          </div>
        </Oval>
      </section>
      <section className="relative bg-contain bg-center bg-no-repeat min-h-screen py-32">
        <div className="container mx-auto px-4 sm:px-8 z-10 relative">
          <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight whitespace-pre-wrap">
            Work with us.
            <br />
            Grow with us.
          </h3>
          <div className="max-w-4xl mx-auto mt-16 px-4 sm:px-0">
            {renderJobPostings()}
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
}

const QUERY = graphql`
  {
    pageContent: file(relativePath: { eq: "pages/careers.json" }) {
      childPagesJson {
        title
        description
        headerText
        subHeaderText
      }
    }
    backgroundImage: file(relativePath: { eq: "background-careers-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: JPG) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    lever: allLever {
      edges {
        node {
          id
          lever_id
          text
          hostedUrl
          applyUrl
          categories {
            commitment
            location
            team
          }
          description
          descriptionPlain
          lists {
            text
            content
          }
          additional
          additionalPlain
        }
      }
    }
  }
`;

export default React.memo(CareersPage);
